@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '../css/functions/rem-fn'
@import '../css/mixins/media'

.bs-modal .q-dialog__inner > .bs-agree-to-terms
  padding: 0

.bs-agree-to-terms
  main
    max-inline-size: rem-fn(700)
    padding-block: rem-fn(40)
    padding-inline: rem-fn(30)

    @include from($breakpoint-sm-min)
      margin-inline: auto
      padding-block: rem-fn(60)

  h1
    text-align: center
    margin: 0

  p
    margin: 0
    text-align: left

  .bs-modal__footer
    background-color: #fff
    border: rem-fn(1) solid $bs-light-grey-outline
    border-radius: $bs-border-radius-sm
    bottom: rem-fn(40)
    display: flex
    flex-flow: column
    gap: rem-fn(10)
    inset-inline: rem-fn(30)
    padding: rem-fn(30)
    position: fixed
    z-index: 99

    .bs-btn
      margin-inline: 0

    @include from($breakpoint-sm-min)
      flex-flow: row
      justify-content: space-between

    @include from($breakpoint-md-min)
      inset-inline: calc((100% - #{rem-fn(970)}) / 2)

.bs-a-t-t-checkbox
  max-inline-size: rem-fn(375)

  .bs-btn
    display: inline
    font-size: rem-fn(15)
    font-weight: normal
    line-height: line-height(20, 15)

  .q-field__bottom
    transform: translateY(75%)

